import useSetState from './useSetState';

export default function useModal() {
  const [viewModalRef, setViewModalRef] = useSetState(false);

  const modalClose = () => {
    setViewModalRef(false);
  };

  const modalOpen = () => {
    setViewModalRef(true);
  };
  return { viewModalRef, modalClose, modalOpen };
}
