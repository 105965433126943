import isEqual from 'lodash/isEqual';

export default function useAwaitRefComeTrue(reference: Ref<unknown> | ComputedRef<unknown>) {
  return new Promise((resolve) => {
    const isNull = isEqual(reference.value, {});
    if (reference.value && !isNull) {
      resolve(true);
      return;
    }

    const unwatch = watch(reference, () => {
      resolve(true);
      unwatch();
    });
  });
}
