import { errorMessage, successMessage } from '~/common/feedback';
import { IconColor } from '~/interfaces/publisher';

export const getNewOption = <T>(value: T, name: string) => ({
  value,
  name,
  icon: {
    name: 'icon-park-outline:loading-one',
    color: IconColor.ORANGE,
  },
});
export const getWaitingOption = <T>(value: T, name: string) => ({
  value,
  name,
  icon: {
    name: 'ant-design:field-time-outlined',
    color: IconColor.SKY,
  },
});

export const getFinishedOption = <T>(value: T, name: string) => ({
  value,
  name,
  icon: {
    name: 'ion:checkbox',
    color: IconColor.SALAD,
  },
});

export const getCancelledOption = <T>(value: T, name: string) => ({
  value,
  name,
  icon: {
    name: 'emojione:cross-mark-button',
    color: IconColor.RED,
  },
});

export const copy = async (toCopy: string) => {
  try {
    navigator.clipboard.writeText(toCopy);
    successMessage('Скопировано!');
  } catch (error) {
    errorMessage('Ошибка при копировании');
  }
};
